<template>
  <div class="card card-carousel overflow-hidden h-100 p-0">
    <div
      v-if="loading"
      class="d-flex justify-content-center align-items-center h-100"
    >
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      v-else
      id="carouselExampleCaptions"
      class="carousel slide h-100"
      data-bs-ride="carousel"
      data-bs-interval="5000"
    >
      <div class="carousel-inner border-radius-lg h-100">
        <div
          v-for="(order, index) in orders"
          :key="order._id"
          class="carousel-item h-100"
          :class="{ active: index === 0 }"
          :style="{
            backgroundImage: 'url(' + require('@/assets/img/carousel-2.jpg') + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }"
        >
          <div
            class="carousel-caption d-none d-md-block bottom-0 text-start start-0 ms-5"
          >
            <div
              class="icon icon-shape icon-sm bg-white text-center border-radius-md mb-3"
            >
              <i class="ni ni-box-2 text-dark opacity-10"></i>
             
            </div>
             *<span> Refresh every 5 minutes</span>
            <h5 class="text-white mb-1">
              Order by: {{ order.userId.username }} (Username) - {{ order.userId.first_name }} (First Name)
            </h5>
          
            <p class="text-white">Total: {{ order.total }} TON</p>
            <p class="text-white">Comment: {{ order.transactionId.hash }}</p>
            <p class="text-white">Created At: {{ new Date(order.createdAt).toLocaleDateString() }}</p>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev w-5 me-3"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next w-5 me-3"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import axiosInstance from '@/utils/axios';

const orders = ref([]);
const loading = ref(true);

const fetchOrders = async () => {
  loading.value = true;
  try {
    const orderResponse = await axiosInstance.get('/api/all/order');
    if (Array.isArray(orderResponse.data.data)) {
      orders.value = orderResponse.data.data
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 5);
    } else {
      console.error('Unexpected order data structure:', orderResponse.data);
    }
  } catch (error) {
    console.error('Error fetching orders:', error);
  } finally {
    loading.value = false;
  }
};

const startAutoRefresh = () => {
  const interval = setInterval(fetchOrders, 300000);  
  return interval;
};

onMounted(() => {
  fetchOrders();
  const intervalId = startAutoRefresh();

  onBeforeUnmount(() => {
    clearInterval(intervalId);
  });
});
</script>
