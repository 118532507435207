<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const layout = computed(() => store.state.layout);

const logout = () => {
  store.dispatch('logout');
  router.push('/signin'); 
};

defineProps({
  card: {
    type: Object,
    required: true,
    title: String,
    description: String,
    links: {
      type: Array,
      label: String,
      route: String,
      color: String,
    },
  },
});
</script>

<template>
  <div
    v-show="layout !== 'landing'"
    class="card card-plain shadow-none"
    id="sidenavCard"
  >
 
    <button @click="logout" class="mb-3 btn btn-danger btn-sm w-100">
      Logout
    </button>

   
  </div>
</template>

