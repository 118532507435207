<template>
  <div class="card p-4">
<h3>Statistics User</h3>
    <h5 for="stat-type" class="mb-0">Select statistics type</h5>
    <div class="d-flex align-items-center justify-content-start text-center">

      <select class="btn btn-primary" id="stat-type" v-model="statType" @change="fetchData">
        <option class="btn-grad" value="month">Monthly</option>
        <option value="day">By day</option>
      </select>
      
    </div>

    <div v-if="statType === 'day'">
      <label for="start-date">Start date:</label>
      <input type="date" id="start-date" v-model="startDate" @change="fetchData" />

      <label for="end-date">End date:</label>
      <input type="date" id="end-date" v-model="endDate" @change="fetchData" />
    </div>

    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import axiosInstance from '@/utils/axios';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';

ChartJS.register(Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  setup() {
    const chartData = ref(null);
    const chart = ref(null);
    const statType = ref('month'); 
    const startDate = ref(dayjs().startOf('month').format('YYYY-MM-DD'));  
    const endDate = ref(dayjs().endOf('month').format('YYYY-MM-DD'));  
    let currentChart = null;  

    onMounted(() => {
      fetchData();
    });

    onBeforeUnmount(() => {
      if (currentChart) {
        currentChart.destroy();
      }
    });

    const fetchData = debounce(async () => {
      try {
        let response;
        if (statType.value === 'month') {
          response = await axiosInstance.get('/api/user/month/test');
        } else {
          response = await axiosInstance.post('/api/user/day', {
            startDate: startDate.value,
            endDate: endDate.value,
          });
        }

        const data = response.data.data;
 
        const labels = data.map((item) => {
          if (statType.value === 'month') {
            const monthName = dayjs().month(item._id.month - 1).format('MMMM');
            return `${monthName} ${item._id.year}`;
          } else {
            return dayjs(new Date(item._id.year, item._id.month - 1, item._id.day)).format('DD ');
          }
        });

        const values = data.map((item) => item.userCount);

        chartData.value = {
          labels,
          datasets: [
            {
              label: statType.value === 'month' ? 'Number of users per month' : 'Number of users per day',
              backgroundColor: '#42A5F5',
              data: values,
            },
          ],
        };

        renderChart();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }, 300);

    const renderChart = () => {
      if (currentChart) {
        currentChart.destroy();  
      }

      if (chart.value) {
        const ctx = chart.value.getContext('2d');
        currentChart = new ChartJS(ctx, {
          type: 'bar',
          data: chartData.value,
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              tooltip: {
                enabled: true,
              },
            },
          },
        });
      }
    };

    return {
      chart,
      chartData,
      statType,
      startDate,
      endDate,
      fetchData,
    };
  },
};
</script>

<style scoped>
canvas {
  width: 100%;
  height: 400px;
}
</style>
