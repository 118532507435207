<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Package Table</h6>
      <button @click="openAddModal" class="btn btn-primary">Add Package</button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Package Name</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Price</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Amount</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Minting Power</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Level</th>
              <th class="text-secondary opacity-7">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(packageItem, index) in packages" :key="index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ packageItem.name_package }}</h6>
                  </div>
                </div>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ packageItem.price }}</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ packageItem.amount }}</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ packageItem.mintingPower }}</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ packageItem.levelPackage }}</span>
              </td>
              <td class="align-middle">
                <button href="javascript:;" class="btn btn-warning me-2" @click="openEditModal(packageItem)">
                  Edit
                </button>
                <button href="javascript:;" class=" btn btn-danger" @click="deletePackage(packageItem._id)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
 
 <!-- Modal for adding and editing packages -->
<div v-if="showModal" class="modal-overlay">
  <div class="modal-container">
    <div class="modal-header">
      <h5>{{ isEditing ? 'Edit Package' : 'Add Package' }}</h5>
      <button type="button" class="close" @click="closeModal">&times;</button>
    </div>
    <div class="modal-body">
      <form @submit.prevent="isEditing ? updatePackage() : savePackage()">
        <div class="form-group">
          <label for="name_package">Package Name</label>
          <input type="text" v-model="currentPackage.name_package" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="price">Price</label>
          <input type="number" v-model="currentPackage.price" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="amount">Amount</label>
          <input type="number" v-model="currentPackage.amount" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="mintingPower">Minting Power</label>
          <input type="number" v-model="currentPackage.mintingPower" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="levelPackage">Level</label>
          <input type="number" v-model="currentPackage.levelPackage" class="form-control" required />
        </div>
        <button type="submit" class="btn btn-primary">{{ isEditing ? 'Update' : 'Save' }}</button>
      </form>
    </div>
  </div>
</div>


  </div>
</template>

<script>
import axiosInstance from '@/utils/axios'; // Sử dụng axiosInstance

export default {
  data() {
    return {
      packages: [], // Lưu trữ danh sách các gói
      showModal: false, // Điều khiển hiển thị modal
      isEditing: false, // Kiểm tra xem đang ở chế độ chỉnh sửa hay thêm mới
      currentPackage: { // Gói hiện tại để thêm hoặc chỉnh sửa
        name_package: '',
        price: 0,
        amount: 0,
        mintingPower: 0,
        levelPackage: 0
      },
      editingId: null, // ID của gói đang chỉnh sửa
    };
  },
  methods: {
    // Fetch tất cả các package từ API
    async fetchPackages() {
      try {
        const response = await axiosInstance.get('/api/all/package');
        this.packages = response.data.data;
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    },
    // Mở modal để thêm package
    openAddModal() {
      this.resetCurrentPackage();
      this.isEditing = false;
      this.showModal = true;
    },
    // Mở modal để chỉnh sửa package
    openEditModal(packageItem) {
      this.currentPackage = { ...packageItem };
      this.editingId = packageItem._id;
      this.isEditing = true;
      this.showModal = true;
    },
    // Đóng modal
    closeModal() {
      this.showModal = false;
    },
    // Lưu package mới
    async savePackage() {
      try {
        await axiosInstance.post('/api/save/package', this.currentPackage);
        this.fetchPackages();
        this.closeModal();
      } catch (error) {
        console.error("Error saving package:", error);
      }
    },
    // Cập nhật package
    async updatePackage() {
      try {
        await axiosInstance.put(`/api/update/package/${this.editingId}`, this.currentPackage);
        this.fetchPackages();
        this.closeModal();
      } catch (error) {
        console.error("Error updating package:", error);
      }
    },
    // Xóa package
    async deletePackage(id) {
      try {
        await axiosInstance.delete(`/api/package/${id}`);
        this.fetchPackages(); // Gọi lại API để cập nhật danh sách sau khi xóa
      } catch (error) {
        console.error("Error deleting package:", error);
      }
    },
    // Reset current package
    resetCurrentPackage() {
      this.currentPackage = {
        name_package: '',
        price: 0,
        amount: 0,
        mintingPower: 0,
        levelPackage: 0
      };
      this.editingId = null;
    }
  },
  // Tự động gọi API khi component được mounted
  mounted() {
    this.fetchPackages();
  }
};
</script>

<style scoped>
/* Thêm CSS tùy chỉnh nếu cần */
.modal {
  display: block;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  margin-top: 20px;
}

.close {
  background: none;
  border: none;
  font-size: 24px;
}

</style>
