<template>
  <div class="card">
    <div class="card-header pb-0">
      <h4>Total Withdraws: {{ data.length }}</h4>
      <p>* Export file by select tag: For example select Ton then will export ton *</p>
      <div class="d-flex justify-start">
        <select v-model="filterType" @change="applyFilter" class="filter-select btn me-2">
          <option value="">Filter by Coin</option>
          <option value="TON">TON</option>
          <option value="TONONE">TONONE</option>
          <option value="USDT">USDT</option>
        </select>


        <button @click="handleExportExcel" class="btn-export btn btn-sm">Export to Excel</button>
      </div>

      <modal v-if="isModalOpen" @close="isModalOpen = false">
        <template #title>
          {{ editingRecord ? 'Edit Withdraw' : 'Add Withdraw' }}
        </template>
        <template #content>
          <form @submit.prevent="handleSave">
            <div class="form-group">
              <label>Username</label>
              <input type="text" v-model="form.username" disabled />
            </div>
            <div class="form-group">
              <label>Amount</label>
              <input type="number" v-model="form.value" disabled />
            </div>
            <div class="form-group">
              <label>Wallet Address</label>
              <input type="text" v-model="form.wallet_address" disabled />
            </div>
            <div class="form-group">
              <label>Status</label>
              <select v-model="form.status">
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div class="form-actions">
              <button type="submit">Save</button>
              <button type="button" @click="isModalOpen = false">Cancel</button>
            </div>
          </form>
        </template>
      </modal>
    </div>
    <div v-if="notification.message" class="alert"
      :class="notification.type === 'success' ? 'alert-success' : 'alert-danger'">
      {{ notification.message }}
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center test-start">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Username</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">First Name</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Wallet Address
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Amount</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Total Reward
                TON
                Ref</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Total Reward
                TONONE Ref</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Day
              </th>

            </tr>
          </thead>
          <tbody class="text-start">
            <tr v-for="record in paginatedData" :key="record._id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-start">
                    <h6 class="mb-0 text-sm">{{ record.userId?.username }}</h6>
                    <!-- <h6 class="mb-0 text-sm">{{ record.userId?._id }}</h6> -->
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ record.userId?.first_name }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ record.wallet_address }}</p>
              </td>
              <td class="align-middle text-center text-sm">
                <div class="text-xs font-weight-bold mb-0 py-3">  {{ getAmountText(record.value) }} | {{ record.coinName ? record.coinName.toLowerCase() : 'Unknown' }} 
    <img :src="record.coinImage" alt="Coin Image" style="width: 40px; height: 40px;"></div>

                <button v-if="!record.status" @click="handleWithdraw(record, getAmountText(record.value))"
                  class="btn-withdraw btn btn-sm btn-primary" :disabled="record.isLoading">
                  <span v-if="record.isLoading" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                  <span v-else>Withdraw</span>
                </button>

              </td>
              <td class="align-middle text-center">
                <button class="btn btn-sm" :style="{ color: record.status ? 'green' : 'red' }">
                  {{ record.status ? 'Success' : 'Pending approval' }}

                </button>
              </td>
              <td class="text-xs font-weight-bold mb-0 text-center">
                {{ record.userId?.totalRewardRef != null ? formatNumber(record.userId.totalRewardRef) : '0' }}
              </td>
              <td class="text-xs font-weight-bold mb-0 text-center">
                {{ record.userId?.totalRewardToken != null ? formatNumber(record.userId.totalRewardToken) : '0' }}
              </td>

              <td class="text-xs font-weight-bold mb-0 text-center">

                Created At {{ formatDate(record.createdAt) }}<br>
                Updated At {{ formatDate(record.updatedAt) }}

              </td>

            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination-controls d-flex justify-content-center p-2">
        <button class="btn btn-sm " @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button class="btn btn-sm" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axiosInstance from '@/utils/axios';
import * as XLSX from 'xlsx';
import qs from 'qs';
const data = ref([]);
const filteredData = ref([]);
const loading = ref(false);
const isModalOpen = ref(false);
const editingRecord = ref(null);
const filterType = ref('');
const notification = ref({ type: '', message: '' });
const currentPage = ref(1);
const itemsPerPage = ref(10);

const form = ref({
  username: '',
  value: 0,
  wallet_address: '',
  status: ''
});

// const fetchWithdraws = async () => {
//   loading.value = true;
//   try {
//     const response = await axiosInstance.get('/api/all/withdraw');
//     console.log(response, "user")
//     data.value = response.data.data;
//     filteredData.value = response.data.data;
//   } catch (error) {
//     console.error('Failed to fetch withdraws');
//   } finally {
//     loading.value = false;
//   }
// };
const fetchWithdraws = async () => {
  loading.value = true;
  try {
    const response = await axiosInstance.get('/api/all/withdraw');
    console.log(response, "user");

    const withdraws = response.data.data;

    const withdrawsWithCoin = await Promise.all(withdraws.map(async (withdraw) => {
      if (withdraw.coin) {
        try {
          const coinResponse = await axiosInstance.get(`/api/coin/${withdraw.coin}`);
          withdraw.coinName = coinResponse.data.data.name;
          withdraw.coinImage = coinResponse.data.data.image[0];

          console.log("Coin Name:", withdraw.coinName); // Kiểm tra giá trị coinName ở đây
        } catch (coinError) {
          console.error(`Failed to fetch coin details for coin ID ${withdraw.coin}:`, coinError);
          withdraw.coinName = 'Unknown';
        }
      } else {
        withdraw.coinName = 'Unknown';
      }
      return withdraw;
    }));

    data.value = withdrawsWithCoin;
    filteredData.value = withdrawsWithCoin;
  } catch (error) {
    console.error('Failed to fetch withdraws', error);
  } finally {
    loading.value = false;
  }
};


// const applyFilter = () => {
//   filteredData.value = data.value.filter((record) => {
//     const amountText = record.value > 10.0 ? 'TONONE' : 'TON';
//     return filterType.value === '' || amountText === filterType.value;
//   });
//   currentPage.value = 1;
// };
const applyFilter = () => {
  filteredData.value = data.value.filter((record) => {
    return filterType.value === '' || record.coinName.toLowerCase() === filterType.value.toLowerCase();
  });
  currentPage.value = 1;
};


const paginatedData = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  return filteredData.value.slice(startIndex, endIndex);
});

const totalPages = computed(() => {
  return Math.ceil(filteredData.value.length / itemsPerPage.value);
});

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const handleSave = async () => {
  try {
    if (editingRecord.value) {
      await axiosInstance.put(`/api/update/withdraw/${editingRecord.value._id}`, form.value);
      console.log('Withdraw updated successfully');
    } else {
      await axiosInstance.post('/api/save/withdraw/token', form.value);
      console.log('Withdraw added successfully');
    }
    isModalOpen.value = false;
    fetchWithdraws();
  } catch (error) {
    console.error('Failed to save withdraw');
  }
};



const handleWithdraw = async (record, amountType) => {
  record.isLoading = true;
  try {
    const apiUrl = amountType.includes('TONONE')
      ? '/api/save/withdraw/token'
      : '/api/transfer/ton';

    const payload = {
      value: record.value,
      userId: record.userId._id,
      to: record.wallet_address,
      status: true,
      _id: record._id
    };

    console.log(payload, "payload");

    const response = await axiosInstance.post(apiUrl, qs.stringify(payload), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    console.log(response, "rut");

    if (response.data.success) {
      notification.value = { type: 'success', message: 'Withdraw request submitted successfully' };
      record.status = true;
      setTimeout(() => {
        notification.value = { type: '', message: '' };
      }, 3000);
      fetchWithdraws();
    } else {
      notification.value = { type: 'error', message: 'Failed to submit withdraw request' };
    }
  } catch (error) {
    notification.value = { type: 'error', message: 'An error occurred while processing the withdraw request' };
    console.error('An error occurred while processing the withdraw request', error);
  } finally {

    record.isLoading = false;
  }
};

const handleExportExcel = () => {
  const exportData = filteredData.value.map(record => {
    const amount = `${record.value?.toFixed(1) || '0.0'} ${record.coinName || 'Unknown'}`;

    return {
      Username: record.userId.username,
      'First Name': record.userId.first_name,
      'Wallet Address': record.wallet_address,
      Amount: amount,
      Status: record.status ? 'Active' : 'Inactive',
      'Total Reward TON Ref': record.userId.totalRewardRef !== null ? `${record.userId.totalRewardRef.toFixed(1)} TON` : '0',
      'Total Reward TONONE Ref': record.userId.totalRewardToken !== null ? `${record.userId.totalRewardToken.toFixed(1)} TONONE` : '0',
      'Created At': new Date(record.createdAt).toLocaleString(),
      'Updated At': new Date(record.updatedAt).toLocaleString(),
    };
  });

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Withdraws');
  XLSX.writeFile(workbook, 'Withdraws.xlsx');
};

const getAmountText = (value) => {
  if (value === null || value === undefined) return '0,00';

  
  const formattedValue = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  return formattedValue; 
};

const formatNumber = (value) => {
  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

const formatDate = (date) => {
  return new Date(date).toLocaleString();
};

onMounted(fetchWithdraws);
</script>

<style scoped>
.modal {
  display: block;
}

.form-group {
  margin-bottom: 16px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-controls button {
  margin: 0 10px;
}
</style>
