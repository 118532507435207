<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);

const userRole = computed(() => {
  const user = store.state.user;
  console.log(user);  
  return user && user.role ? user.role : '';  
});

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};
</script>
<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
  <ul class="navbar-nav">
  <li class="nav-item" v-if="userRole === 'admin' || userRole === 'seller'">
    <sidenav-item
      to="/dashboard-default"
      :class="getRoute() === 'dashboard-default' ? 'active' : ''"
      :navText="isRTL ? 'Dashboard' : 'Dashboard'"
    >
      <template v-slot:icon>
        <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i> <!-- Dashboard -->
      </template>
    </sidenav-item>
  </li>

  <!-- <li class="nav-item">
    <sidenav-item
      to="/tables"
      :class="getRoute() === 'tables' ? 'active' : ''"
      :navText="isRTL ? 'الجداول' : 'Tables'"
    >
      <template v-slot:icon>
        <i class="ni ni-bullet-list-67 text-warning text-sm opacity-10"></i> 
      </template>
    </sidenav-item>
  </li> -->


   <li class="nav-item" v-if="userRole === 'admin' || userRole === 'seller'">
    <sidenav-item
      to="/statistical"
      :class="getRoute() === 'statistical' ? 'active' : ''"
      :navText="isRTL ? 'الجداول' : 'statistical'"
    >
      <template v-slot:icon>
        <i class="ni ni-bullet-list-67 text-warning text-sm opacity-10"></i>  
      </template>
    </sidenav-item>
  </li>

<li class="nav-item" v-if="userRole === 'admin' || userRole === 'seller'">
  <sidenav-item
    to="/order"
    :class="getRoute() === 'order' ? 'active' : ''"
    :navText="isRTL ? 'Order' : 'Order'"
  >
    <template v-slot:icon>
      <i class="ni ni-delivery-fast text-warning text-sm opacity-10"></i>  
    </template>
  </sidenav-item>
</li>


  <li class="nav-item" v-if="userRole === 'admin' || userRole === 'seller'">
    <sidenav-item
      to="/task"
      :class="getRoute() === 'task' ? 'active' : ''"
      :navText="isRTL ? 'Task' : 'Task'"
    >
      <template v-slot:icon>
        <i class="ni ni-check-bold text-success text-sm opacity-10"></i> <!-- Task -->
      </template>
    </sidenav-item>
  </li>

  <li class="nav-item" v-if="userRole === 'admin' || userRole === 'seller'">
    <sidenav-item
      to="/user"
      :class="getRoute() === 'user' ? 'active' : ''"
      :navText="isRTL ? 'user' : 'User'"
    >
      <template v-slot:icon>
        <i class="ni ni-circle-08 text-info text-sm opacity-10"></i> <!-- User -->
      </template>
    </sidenav-item>
  </li>
  <li class="nav-item" v-if="userRole === 'admin' || userRole === 'seller'">
    <sidenav-item
      to="/useref"
      :class="getRoute() === 'userref' ? 'active' : ''"
      :navText="isRTL ? 'userref' : 'User Ref'"
    >
      <template v-slot:icon>
        <i class="ni ni-circle-08 text-info text-sm opacity-10"></i> <!-- User -->
      </template>
    </sidenav-item>
  </li>
  <li class="nav-item" v-if="userRole === 'admin'">
    <sidenav-item
      to="/withdraw"
      :class="getRoute() === 'withdraw' ? 'active' : ''"
      :navText="isRTL ? 'Withdraw' : 'Withdraw'"
    >
      <template v-slot:icon>
        <i class="ni ni-money-coins text-danger text-sm opacity-10"></i> <!-- Withdraw -->
      </template>
    </sidenav-item>
  </li>


  <!-- <li class="nav-item">
    <sidenav-item
      to="/billing"
      :class="getRoute() === 'billing' ? 'active' : ''"
      :navText="isRTL ? 'الفواتیر' : 'Billing'"
    >
      <template v-slot:icon>
        <i class="ni ni-credit-card text-success text-sm opacity-10"></i>  
      </template>
    </sidenav-item>
  </li> -->
<!-- 
  <li class="nav-item">
    <sidenav-item
      to="/virtual-reality"
      :class="getRoute() === 'virtual-reality' ? 'active' : ''"
      :navText="isRTL ? 'الواقع الافتراضي' : 'Virtual Reality'"
    >
      <template v-slot:icon>
        <i class="ni ni-app text-info text-sm opacity-10"></i>  
      </template>
    </sidenav-item>
  </li> -->
<!-- 
  <li class="nav-item">
    <sidenav-item
      to="/rtl-page"
      :class="getRoute() === 'rtl-page' ? 'active' : ''"
      navText="RTL"
    >
      <template v-slot:icon>
        <i class="ni ni-world text-danger text-sm opacity-10"></i>  
      </template>
    </sidenav-item>
  </li> -->

  <li class="mt-3 nav-item" v-if="userRole === 'admin'  ">
    <h6
      v-if="isRTL"
      class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
      :class="isRTL ? 'me-4' : 'ms-2'"
    >
      صفحات المرافق
    </h6>

    <h6
      v-else
      class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
      :class="isRTL ? 'me-4' : 'ms-2'"
    >
      ACCOUNT PAGES
    </h6>
  </li>
    <li class="nav-item" v-if="userRole === 'admin'">
    <sidenav-item
      to="/createaccount"
      :class="getRoute() === 'createaccount' ? 'active' : ''"
      :navText="isRTL ? 'Create Account' : 'Create Account'"
    >
      <template v-slot:icon>
      <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
      </template>
    </sidenav-item>
  </li>
    <li class="nav-item" v-if="userRole === 'admin'">
    <sidenav-item
      to="/configspin"
      :class="getRoute() === 'configspin' ? 'active' : ''"
      :navText="isRTL ? 'Config Spin' : 'Config Spin'"
    >
      <template v-slot:icon>
     <i class="ni ni-settings text-primary text-sm opacity-10"></i>
      </template>
    </sidenav-item>
  </li>
   <li class="nav-item" v-if="userRole === 'admin' ">
    <sidenav-item
      to="/package"
      :class="getRoute() === 'package' ? 'active' : ''"
      :navText="isRTL ? 'package' : 'package'"
    >
      <template v-slot:icon>
        <i class="ni ni-check-bold text-success text-sm opacity-10"></i> <!-- Task -->
      </template>
    </sidenav-item>
  </li>
  <!-- <li class="nav-item ">
    <sidenav-item
      to="/profile"
      :class="getRoute() === 'profile' ? 'active' : ''"
      :navText="isRTL ? 'حساب تعريفي' : 'Profile'"
    >
      <template v-slot:icon>
        <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>  
      </template>
    </sidenav-item>
  </li> -->

  <!-- <li class="nav-item">
    <sidenav-item
      to="/signin"
      :class="getRoute() === 'signin' ? 'active' : ''"
      :navText="isRTL ? 'تسجيل الدخول' : 'Sign In'"
    >
      <template v-slot:icon>
        <i class="ni ni-key-25 text-danger text-sm opacity-10"></i>  
      </template>
    </sidenav-item>
  </li> -->

  <!-- <li class="nav-item">
    <sidenav-item
      to="/signup"
      :class="getRoute() === 'signup' ? 'active' : ''"
      :navText="isRTL ? 'اشتراك' : 'Sign Up'"
    >
      <template v-slot:icon>
        <i class="ni ni-badge text-info text-sm opacity-10"></i>  
      </template>
    </sidenav-item>
  </li> -->
</ul>

    <!-- <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/dashboard-default"
          :class="getRoute() === 'dashboard-default' ? 'active' : ''"
          :navText="isRTL ? 'Dashboard' : 'Dashboard'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/tables"
          :class="getRoute() === 'tables' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Tables'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-calendar-grid-58 text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
        <li class="nav-item">
        <sidenav-item
          to="/order"
          :class="getRoute() === 'tables' ? 'active' : ''"
          :navText="isRTL ? 'Order' : 'Order'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-calendar-grid-58 text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
        <li class="nav-item">
        <sidenav-item
          to="/task"
          :class="getRoute() === 'tables' ? 'active' : ''"
          :navText="isRTL ? 'Task' : 'Task'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-calendar-grid-58 text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
   <li class="nav-item">
        <sidenav-item
          to="/user"
          :class="getRoute() === 'tables' ? 'active' : ''"
          :navText="isRTL ? 'user' : 'User'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-calendar-grid-58 text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
        <li class="nav-item">
        <sidenav-item
          to="/withdraw"
          :class="getRoute() === 'tables' ? 'active' : ''"
          :navText="isRTL ? 'Withdraw' : 'Withdraw'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-calendar-grid-58 text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/billing"
          :class="getRoute() === 'billing' ? 'active' : ''"
          :navText="isRTL ? 'الفواتیر' : 'Billing'"
        >
          <template v-slot:icon>
            <i class="ni ni-credit-card text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/virtual-reality"
          :class="getRoute() === 'virtual-reality' ? 'active' : ''"
          :navText="isRTL ? 'الواقع الافتراضي' : 'Virtual Reality'"
        >
          <template v-slot:icon>
            <i class="ni ni-app text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/rtl-page"
          :class="getRoute() === 'rtl-page' ? 'active' : ''"
          navText="RTL"
        >
          <template v-slot:icon>
            <i class="ni ni-world-2 text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="mt-3 nav-item">
        <h6
          v-if="isRTL"
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          صفحات المرافق
        </h6>

        <h6
          v-else
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          ACCOUNT PAGES
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/profile"
          :class="getRoute() === 'profile' ? 'active' : ''"
          :navText="isRTL ? 'حساب تعريفي' : 'Profile'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/signin"
          :class="getRoute() === 'signin' ? 'active' : ''"
          :navText="isRTL ? 'تسجيل الدخول' : 'Sign In'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-copy-04 text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/signup"
          :class="getRoute() === 'signup' ? 'active' : ''"
          :navText="isRTL ? 'اشتراك' : 'Sign Up'"
        >
          <template v-slot:icon>
            <i class="ni ni-collection text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
    </ul> -->
  </div>

  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :card="{
        title: 'Need Help?',
        description: 'Please check our docs',
        links: [
          {
            label: 'Documentation',
            route:
              'https://www.creative-tim.com/learning-lab/vue/overview/argon-dashboard/',
            color: 'dark',
          },
          {
            label: 'Buy now',
            route:
              'https://www.creative-tim.com/product/vue-argon-dashboard-pro?ref=vadp',
            color: 'success',
          },
        ],
      }"
    />
  </div>
</template>
