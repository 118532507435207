<template>
  <div class="card">
    <div class="card-header ">
      <button @click="handleAdd" class="btn btn-primary mb-3">Add Task</button>
      <table class="table text-center align-items-center">
        <thead>
          <tr>
            <th>Title</th>
            <th>Amount</th>
            <th>Coin Name</th>
            <th>Coin Image</th>
            <!-- <th>Status</th> -->
            <th>Task Image</th>
            <th>Task URL</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="task in tasks" :key="task._id">
            <td>{{ task.title }}</td>
            <td>{{ task.amount }}</td>
            <td>{{ typeof task.id_coin === 'object' ? task.id_coin?.name : task.id_coin }}</td>
            <td>
              <img v-if="typeof task.id_coin === 'object' && task.id_coin?.image.length > 0" 
                   :src="task.id_coin.image[0]" 
                   alt="coin" 
                   class="coin-image" />
            </td>
            <!-- <td>{{ task.status ? 'Active' : 'Inactive' }}</td> -->
            <td>
              <img v-if="task.image.length > 0" 
                   :src="task.image[0]" 
                   alt="task" 
                   class="task-image" />
            </td>
            <td><a class="truncated-link" :href="task.url" target="_blank">{{ task.url }}</a></td>
            <td>{{ new Date(task.createdAt).toLocaleString() }}</td>
            <td>{{ new Date(task.updatedAt).toLocaleString() }}</td>
            <td>
              <button @click="handleEdit(task)" class="btn btn-sm btn-warning me-2">Edit</button>
              <button @click="handleDelete(task._id)" class="btn btn-sm btn-danger">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Pagination -->
      <div class="pagination mt-3 flex justify-content-center">
        <button @click="prevPage" :disabled="page === 1" class="btn btn-primary btn-sm">Previous</button>
        <span class="mx-2">{{ page }} / {{ totalPages }}</span>
        <button @click="nextPage" :disabled="page >= totalPages" class="btn btn-primary btn-sm">Next</button>
      </div>

      <!-- Modal -->
      <div v-if="isModalVisible" class="modal d-block" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ isEdit ? 'Edit Task' : 'Add Task' }}</h5>
              <button type="button" class="btn-close" @click="handleCancel"></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="handleOk">
                <div class="mb-3">
                  <label for="title" class="form-label">Title</label>
                  <input type="text" class="form-control" id="title" v-model="form.title" required>
                </div>
                <div class="mb-3">
                  <label for="amount" class="form-label">Amount</label>
                  <input type="number" class="form-control" id="amount" v-model="form.amount" required>
                </div>
                <div class="mb-3">
                  <label for="id_coin" class="form-label">Coin ID</label>
                  <input type="text" class="form-control" id="id_coin" v-model="form.id_coin" disabled>
                </div>
                <div class="mb-3">
                  <label for="url" class="form-label">Task URL</label>
                  <input type="text" class="form-control" id="url" v-model="form.url">
                </div>
                <div class="mb-3">
                  <label for="images" class="form-label">Upload Images</label>
                  <input type="file" class="form-control" multiple @change="handleUploadChange">
                </div>
                <div class="mb-3 form-check">
                  <input type="checkbox" class="form-check-input" id="status" v-model="form.status">
                  <label class="form-check-label" for="status">Status</label>
                </div>
                <div class="d-flex justify-content-between">
                  <button type="submit" class="btn btn-primary">Save</button>
                  <button type="button" class="btn btn-secondary" @click="handleCancel">Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axiosInstance from '@/utils/axios';

const allTasks = ref([]);
const tasks = ref([]);
const page = ref(1);
const pageSize = ref(10);
const totalPages = ref(0);
const isModalVisible = ref(false);
const isEdit = ref(false);
const currentTask = ref(null);
const form = ref({
  title: '',
  amount: 0,
  id_coin: '',
  url: '',
  status: true,
  images: []
});
const fileList = ref([]);
const defaultCoinId = ref('668ce795b96c79501f680734');

const fetchTasks = async () => {
  try {
    const { data } = await axiosInstance.get('/api/all/task');
    if (data && Array.isArray(data.data)) {
      allTasks.value = data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      totalPages.value = Math.ceil(allTasks.value.length / pageSize.value);
      updatePage();
    } else {
      allTasks.value = [];
      totalPages.value = 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const updatePage = () => {
  const start = (page.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  tasks.value = allTasks.value.slice(start, end);
};

const prevPage = () => {
  if (page.value > 1) {
    page.value--;
    updatePage();
  }
};

const nextPage = () => {
  if (page.value < totalPages.value) {
    page.value++;
    updatePage();
  }
};

const handleAdd = () => {
  isEdit.value = false;
  currentTask.value = null;
  form.value = {
    title: '',
    amount: 0,
    id_coin: defaultCoinId.value,
    url: '',
    status: true,
    images: []
  };
  fileList.value = [];
  isModalVisible.value = true;
};

const handleEdit = (record) => {
  isEdit.value = true;
  currentTask.value = record;
  form.value = {
    title: record.title,
    amount: record.amount,
    id_coin: typeof record.id_coin === 'object' ? record.id_coin._id : record.id_coin,
    url: record.url,
    status: record.status,
    images: record.image
  };
  fileList.value = record.image.map((url, index) => ({
    uid: index,
    name: `image-${index}.jpg`,
    status: 'done',
    url,
  }));
  isModalVisible.value = true;
};

const handleDelete = async (_id) => {
  try {
    await axiosInstance.delete(`/api/delete/task/${_id}`);
    fetchTasks();
  } catch (error) {
    console.error('Error deleting task:', error);
  }
};

const handleUploadChange = (event) => {
  fileList.value = Array.from(event.target.files);
};

const handleUpload = async () => {
  const formData = new FormData();
  fileList.value.forEach(file => {
    formData.append('image', file);
  });
  formData.append('title', form.value.title);
  formData.append('amount', form.value.amount);
  formData.append('id_coin', form.value.id_coin);
  formData.append('url', form.value.url);
  formData.append('status', form.value.status);

  try {
    if (isEdit.value && currentTask.value) {
      await axiosInstance.put(`/api/update/task/${currentTask.value._id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    } else {
      await axiosInstance.post('/api/save/task', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    }
    isModalVisible.value = false;
    fetchTasks();
  } catch (error) {
    console.error('Error saving task:', error);
  }
};

const handleOk = async () => {
  await handleUpload();
};

const handleCancel = () => {
  isModalVisible.value = false;
};

onMounted(fetchTasks);
</script>

<style scoped>
/* Table text size */
table {
  font-size: 12px;
}

.coin-image,
.task-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

/* Modal styling */
.modal-backdrop {
  display: none;
}
.modal {
  display: block;
  background: rgba(0, 0, 0, 0.5);
}
.modal-dialog {
  margin-top: 10%;
}
.truncated-link {
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
