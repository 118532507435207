<template>
  <div class="card">
    <div class="card-body px-0 py-4">
      <div v-if="loading" class="spinner">Loading...</div>
      <div v-else-if="!tableData.length">No data available.</div>
      <table v-else class="table-auto w-full">
        <thead>
          <tr>
            <th>Username</th>
            <th>First Name</th>
            <th>CreatedAt</th>
            <th>Level User</th>
            <th>Ref Count</th>
            <th>TonOne Reward Ref</th>
            <th>Ton Reward Ref</th>
            <th>User Name Ref</th>
            <th>Name Ref</th>
            <th>TonOne of Ref</th>
            <th>Ton of Ref</th>
            <th>Level User Ref</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in tableData" :key="`${item._id}-${item.refIndex}`">
            <td>{{ item.username }}</td>
            <td>{{ item.first_name }}</td>
            <td>
              {{
                new Date(item.createdAt).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })
              }}
            </td>
            <td>{{ item.levelUser }}</td>
            <td>{{ item.ref_count }}</td>
            <td>{{ formatNumber(calculateReward(item.levelUser)) }}</td>
            <td>{{ (item.levelUser * 0.1).toFixed(1) }}</td>
            <td>{{ item.refs[item.refIndex]?.username || '' }}</td>
            <td>{{ item.refs[item.refIndex]?.first_name || '' }}</td>
            <td>
              {{
                typeof item.refs?.[item.refIndex]?.balance_token === 'number'
                  ? parseFloat(item.refs[item.refIndex].balance_token.toFixed(3)).toLocaleString('en-US')
                  : item.refs?.[item.refIndex]?.balance_token || ''
              }}
            </td>
            <td>
              {{
                typeof item.refs?.[item.refIndex]?.balance_ton === 'number'
                  ? parseFloat(item.refs[item.refIndex].balance_ton).toLocaleString('en-US')
                  : item.refs?.[item.refIndex]?.balance_ton || ''
              }}
            </td>
            <td>{{ item.refs[item.refIndex]?.levelUser || '' }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Pagination Controls -->
      <div v-if="totalPages > 1" class="pagination">
        <button :disabled="currentPage === 1" @click="changePage(currentPage - 1)">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button :disabled="currentPage === totalPages" @click="changePage(currentPage + 1)">Next</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axiosInstance from '@/utils/axios';

const loading = ref(true);
const users = ref([]);
const currentPage = ref(1);
const pageSize = ref(10);
const totalItems = ref(0); // Tổng số mục từ API

const totalPages = computed(() => Math.ceil(totalItems.value / pageSize.value));

const tableData = computed(() =>
  users.value.flatMap((user) =>
    user.refs.length > 0
      ? user.refs.map((ref, index) => ({
        ...user,
        refIndex: index,
      }))
      : [{ ...user, refIndex: 0 }]
  )
);

const percentRewards = [
  { level: 1, percent: 10 },
  { level: 2, percent: 15 },
  { level: 3, percent: 17.5 },
  { level: 4, percent: 18.75 },
  { level: 5, percent: 19.375 },
  { level: 6, percent: 19.6875 },
  { level: 7, percent: 19.84375 },
  { level: 8, percent: 19.921875 },
  { level: 9, percent: 19.9609375 },
  { level: 10, percent: 19.98046875 },
];

const fetchUsers = async (page = 1, pageSize = 10) => {
  try {
    const { data } = await axiosInstance.get('/api/pagination/user', {
      params: { page, pageSize },
    });
    if (data && Array.isArray(data.data)) {
      totalItems.value = data.total; // Giả định API trả về tổng số mục
      return data.data;
    } else {
      console.error('API did not return expected data format');
      return [];
    }
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
};

const fetchUserRefs = async (userId) => {
  try {
    const { data } = await axiosInstance.get(`/api/child/${userId}`);
    return data.data.map((item) => {
      const user = item.findUserChild;

      return {
        _id: user._id || item._id,
        username: user.username || "",
        first_name: user.first_name || "",
        balance_token: user.balance_token || "0",
        balance_ton: user.balance_ton || "0",
        totalRewardRef: user.totalRewardRef,
        totalRewardToken: user.totalRewardToken,
        levelUser: user.levelUser || "0",
        status: user.status || "",
        createdAt: user.createdAt || "",
        linkRef: user.linkRef || "",
        role: user.role || "",
      };
    });
  } catch (error) {
    console.error(`Failed to fetch refs for user ${userId}`, error);
    return [];
  }
};

const calculateReward = (levelUser) => {
  const reward = percentRewards.find((item) => item.level === levelUser);
  const tononeRewardsValue = Number(process.env.VUE_APP_BASE_REWARD_TONONE || '0');
  if (reward) {
    const rewardValue = (reward.percent * tononeRewardsValue) / 100;
    return rewardValue.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else {
    return '0';
  }
};

const formatNumber = (value) => {
  const numberStr = value.toString();
  if (numberStr.length > 7) {
    return numberStr.slice(0, 7) + '...';
  }
  return numberStr;
};

const loadUsersWithRefs = async () => {
  try {
    loading.value = true;
    const usersData = await fetchUsers(currentPage.value, pageSize.value);
    const userRefs = await Promise.all(
      usersData.map(async (user) => {
        const refs = await fetchUserRefs(user._id);
        const refCount = Array.isArray(refs) ? refs.length : 0;
        return {
          ...user,
          ref_count: refCount,
          refs: refs || [],
        };
      })
    );
    users.value = userRefs;
  } catch (error) {
    console.error('Error fetching data:', error);
    users.value = [];
  } finally {
    loading.value = false;
  }
};

const changePage = async (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
    await loadUsersWithRefs();
  }
};

onMounted(async () => {
  await loadUsersWithRefs();
});
</script>

<style scoped>
.pt-20 {
  padding-top: 20px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.5rem;
}

.table-auto {
  width: 100%;
  border-collapse: collapse;
}

.table-auto th,
.table-auto td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table-auto th {
  background-color: #f2f2f2;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
