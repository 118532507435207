<script setup>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import Carousel from "./components/Carousel.vue";
 

// import CategoriesList from "./components/CategoriesList.vue";
import { ref, onMounted } from 'vue';
// import US from "@/assets/img/icons/flags/US.png";
// import DE from "@/assets/img/icons/flags/DE.png";
// import GB from "@/assets/img/icons/flags/GB.png";
// import BR from "@/assets/img/icons/flags/BR.png";
import axiosInstance from '@/utils/axios';
import StatisticalUser from "./components/StatisticalUser.vue";

// const sales = {
//   us: {
//     country: "United States",
//     sales: 2500,
//     value: "$230,900",
//     bounce: "29.9%",
//     flag: US,
//   },
//   germany: {
//     country: "Germany",
//     sales: "3.900",
//     value: "$440,000",
//     bounce: "40.22%",
//     flag: DE,
//   },
//   britain: {
//     country: "Great Britain",
//     sales: "1.400",
//     value: "$190,700",
//     bounce: "23.44%",
//     flag: GB,
//   },
//   brasil: {
//     country: "Brasil",
//     sales: "562",
//     value: "$143,960",
//     bounce: "32.14%",
//     flag: BR,
//   },
// };

const userCount = ref(0);
const orderCount = ref(0);
const commissionOrder = ref(0);
const commissionFree = ref(0);
const inputDate = ref('');

const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const formatDate = (dateStr) => {
  const [year, month, day] = dateStr.split('-');
  return `${day}-${month}-${year}`;
};

const fetchCommissionFree = async () => {
  try {
    if (inputDate.value) {
      const formattedDate = formatDate(inputDate.value);
      const data = new URLSearchParams();
      data.append('inputDate', formattedDate);


      const commissionFreeResponse = await axiosInstance.post('/api/token/commission/free', data);



      if (typeof commissionFreeResponse.data.data === 'number') {
        commissionFree.value = commissionFreeResponse.data.data;
      } else {
        console.error('Expected a number but got:', commissionFreeResponse.data);
        commissionFree.value = 'Error';
      }
    } else {
      console.error('No date selected');
      commissionFree.value = 'Error';
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    commissionFree.value = 'Error';
    commissionFree.value = 0;
  }
};

onMounted(async () => {

  inputDate.value = getCurrentDate();

  try {
    const userResponse = await axiosInstance.get('/api/all/user');
    console.log(userResponse,userResponse.data,"ssss")
if (typeof userResponse.data.data === 'number') {
  userCount.value = userResponse.data.data;  
} else {
  console.error('Expected a number but got:', userResponse.data.data);
  userCount.value = 'Error';
}

    const orderResponse = await axiosInstance.get('/api/all/order');
    console.log(orderResponse,"oo")
    if (Array.isArray(orderResponse.data.data)) {
      orderCount.value = orderResponse.data.data.length;
    } else {
      console.error('Expected an array but got:', orderResponse.data);
      orderCount.value = 'Error';
    }

    const commissionOrderResponse = await axiosInstance.get('/api/token/commission');
    console.log(commissionOrderResponse,"ádasd")
    if (typeof commissionOrderResponse.data.data === 'number') {
      commissionOrder.value = commissionOrderResponse.data.data;
    } else {
      console.error('Expected a number but got:', commissionOrderResponse.data);
      commissionOrder.value = 'Error';
    }


    await fetchCommissionFree();

  } catch (error) {
    console.error('Error fetching data:', error);
    userCount.value = 'Error';
    orderCount.value = 'Error';
    commissionOrder.value = 'Error';
    commissionFree.value = 0;
  }
});
</script>


<template>


  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-12 py-3 ">

        <input class="rounder btn btn-primary" type="date" id="inputDate" v-model="inputDate"
          @change="fetchCommissionFree" />
      </div>
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">


            <mini-statistics-card title="Free Commission" :value="`${commissionFree.toLocaleString()}`" :icon="{
              component: 'ni ni-cart',
              background: 'bg-gradient-warning',
              shape: 'rounded-circle',
            }" />
          </div>

          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Max Token Order" :value="`${commissionOrder.toLocaleString()}`" :icon="{
              component: 'ni ni-paper-diploma',
              background: 'bg-gradient-success',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Total Order" :value="`${orderCount.toLocaleString()}`" :icon="{
              component: 'ni ni-money-coins',
              background: 'bg-gradient-primary',
              shape: 'rounded-circle',
            }" />
          </div>
          <!-- <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Total Order" :value="`${orderCount.toLocaleString()}`"
              description="<span class='text-sm font-weight-bolder text-success'>as of now</span>" :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }" />
          </div> -->
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Total User" :value="`${userCount.toLocaleString()}`" :icon="{
              component: 'ni ni-world',
              background: 'bg-gradient-danger',
              shape: 'rounded-circle',
            }" />
          </div>



        </div>
        <div class="row">
          <div class="col-lg-7 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <!-- <gradient-line-chart id="chart-line" title="Sales Overview" description="<i class='fa fa-arrow-up text-success'></i>
      <span class='font-weight-bold'>4% more</span> in 2021" :chart="{
        labels: [
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        datasets: [
          {
            label: 'Mobile Apps',
            data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
          },
        ],
      }" /> -->
      <StatisticalUser />
            </div>
          </div>
          <div class="col-lg-5">
            <carousel />
          </div>
        </div>
        <!-- <div class="row mt-4">
          <div class="col-lg-7 mb-lg-0 mb-4">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-2">Sales by Country</h6>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table align-items-center">
                  <tbody>
                    <tr v-for="(sale, index) in sales" :key="index">
                      <td class="w-30">
                        <div class="px-2 py-1 d-flex align-items-center">
                          <div>
                            <img :src="sale.flag" alt="Country flag" />
                          </div>
                          <div class="ms-4">
                            <p class="mb-0 text-xs font-weight-bold">
                              Country:
                            </p>
                            <h6 class="mb-0 text-sm">{{ sale.country }}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Sales:</p>
                          <h6 class="mb-0 text-sm">{{ sale.sales }}</h6>
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Value:</p>
                          <h6 class="mb-0 text-sm">{{ sale.value }}</h6>
                        </div>
                      </td>
                      <td class="text-sm align-middle">
                        <div class="text-center col">
                          <p class="mb-0 text-xs font-weight-bold">Bounce:</p>
                          <h6 class="mb-0 text-sm">{{ sale.bounce }}</h6>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <categories-list :categories="[
              {
                icon: {
                  component: 'ni ni-mobile-button',
                  background: 'dark',
                },
                label: 'Devices',
                description: '250 in stock <strong>346+ sold</strong>',
              },
              {
                icon: {
                  component: 'ni ni-tag',
                  background: 'dark',
                },
                label: 'Tickets',
                description: '123 closed <strong>15 open</strong>',
              },
              {
                icon: { component: 'ni ni-box-2', background: 'dark' },
                label: 'Error logs',
                description: '1 is active <strong>40 closed</strong>',
              },
              {
                icon: { component: 'ni ni-satisfied', background: 'dark' },
                label: 'Happy Users',
                description: '+ 430',
              },
            ]" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
