import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import store from '../store/index';
import Order from "../views/Order.vue";
import User from "../views/User.vue";
import Task from "../views/Task.vue";
import CreateAccount from "../views/CreateAccount.vue";
import Withdraw from "../views/Withdraw.vue";
import Statistic from "../views/Statistic.vue";
import UserRef from "../views/UserRef.vue";
import ConfigSpin from "../views/ConfigSpin.vue";
import Packages from "../views/Packages.vue";
 const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard-default",
     meta: { requiresAuth: true, role: ['admin', 'seller'] }, 
  },
  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Dashboard,
  meta: { requiresAuth: true, role: ['admin', 'seller'] }, 
  },
    {
    path: "/statistical",
    name: "Static",
    component: Statistic,
      meta: { requiresAuth: true, role: ['admin', 'seller'] }, 
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
     meta: { requiresAuth: true, role: ['admin', 'seller'] }, 
  },
    {
    path: "/order",
    name: "Order",
    component: Order,
   meta: { requiresAuth: true, role: ['admin', 'seller'] }, 
  },

     {
    path: "/task",
    name: "Task",
    component: Task,
   meta: { requiresAuth: true, role: ['admin', 'seller'] }, 
  },
      {
    path: "/user",
    name: "User",
    component: User,
      meta: { requiresAuth: true, role: ['admin', 'seller'] }, 
  },
   {
    path: "/configspin",
    name: "configspin",
    component: ConfigSpin,
      meta: { requiresAuth: true, role: ['admin',] }, 
  },
      {
    path: "/useref",
    name: "Useref",
    component: UserRef,
   meta: { requiresAuth: true, role: ['admin', 'seller'] }, 
  },
  
      {
    path: "/withdraw",
    name: "Withdraw",
    component: Withdraw,
      meta: { requiresAuth: true, role: ['admin', 'seller'] }, 
  },
  
      {
    path: "/createaccount",
    name: "createaccount",
    component: CreateAccount,
      meta: { requiresAuth: true, role: ['admin', 'seller'] }, 
  },
    {
    path: "/package",
    name: "package",
    component: Packages,
   meta: { requiresAuth: true, role: ['admin' ] }, 
  },
  
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});
// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//   const userRole = store.getters.user ? store.getters.user.role : null;

//   if (requiresAuth && !store.getters.isAuthenticated) {
 
//     next('/signin');
//   } else if (requiresAuth && to.meta.role && to.meta.role !== userRole) {
 
//     next('/unauthorized');  
//   } else {
 
//     next();
//   }
// });

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const userRole = store.getters.user ? store.getters.user.role : null;

  if (requiresAuth && !store.getters.isAuthenticated) {
    next('/signin');
  } else if (requiresAuth && to.meta.role && !to.meta.role.includes(userRole)) {
    next('/unauthorized');
  } else {
    next();
  }
});

export default router;
