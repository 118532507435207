<script setup>
import { onBeforeUnmount, onBeforeMount, ref } from "vue";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import axiosInstance from '@/utils/axios';

import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";

const store = useStore();
const router = useRouter();

const username = ref('');
const password = ref('');
const loginType = ref('admin');  
const loginError = ref(null);

const LOGIN_URL_ADMIN = "/api/login/admin";
const LOGIN_URL_SELLER = "/api/login/seller";

// const login = async () => {
//   try {

//     const loginUrl = loginType.value === 'admin' ? LOGIN_URL_ADMIN : LOGIN_URL_SELLER;

//     const response = await axiosInstance.post(loginUrl, {
//       username: username.value,
//       password: password.value,
//     });



 
//     const user = response.data.user;
//     const accessToken = user.accessToken;  
 
//     const authData = {
//       _id: user._id,
//       username: user.username,
//       role: user.role,
//       __v: user.__v,
//       accessToken: accessToken,
//     };

 
//     store.commit('setUser', authData);
//     store.commit('setToken', authData.accessToken);

 
//     localStorage.setItem('auth', JSON.stringify(authData));

 
//     axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${authData.accessToken}`;

 
//     router.push('/dashboard-default');  
//   } catch (error) {
//     console.error('Login failed:', error);
//     loginError.value = 'Login failed. Please check your credentials and try again.';
//   }
// };


const login = async () => {
  console.log("Login function triggered");  // This should always log when you click the submit button

  try {
    const loginUrl = loginType.value === 'admin' ? LOGIN_URL_ADMIN : LOGIN_URL_SELLER;
    const response = await axiosInstance.post(loginUrl, {
      username: username.value,
      password: password.value,
    });
    
    console.log(response,"Login response"); // Log the response to check if it reaches here
    
    const user = response.data.user;
    const accessToken = user.accessToken;  

    const authData = {
      _id: user._id,
      username: user.username,
      role: user.role,
      __v: user.__v,
      accessToken: accessToken,
    };

    store.commit('setUser', authData);
    store.commit('setToken', authData.accessToken);
    
    localStorage.setItem('auth', JSON.stringify(authData));
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${authData.accessToken}`;
    router.push('/dashboard-default');  
  } catch (error) {
    console.error('Login failed:', error);
    loginError.value = 'Login failed. Please check your credentials and try again.';
  }
};

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  document.body.classList.remove("bg-gray-100");
});

onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  document.body.classList.add("bg-gray-100");
});
</script>


<!-- <template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your username and password to sign in</p>
                </div>
                <div class="card-body">
                  <form @submit.prevent="login">
                    <div class="mb-3">
                      <argon-input
                        v-model="username"
                        id="username"
                        type="text"
                        placeholder="Username"
                        name="username"
                        size="lg"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        v-model="password"
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="lg"
                      />
                    </div>
                    <argon-switch id="rememberMe" name="remember-me">Remember me</argon-switch>
                    <div v-if="loginError" class="text-danger">{{ loginError }}</div>  
                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                        type="submit"
                      >Sign in</argon-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg&quot;);
                  background-size: cover;
                "
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4 class="mt-5 text-white font-weight-bolder position-relative">
                  "Attention is the new currency"
                </h4>
                <p class="text-white position-relative">
                  The more effortless the writing looks, the more effort the
                  writer actually put into the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template> -->
<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your username and password to sign in</p>
                </div>
                <div class="card-body">
                  <form @submit.prevent="login">
                    <div class="mb-3">
                      <argon-input
                        v-model="username"
                        id="username"
                        type="text"
                        placeholder="Username"
                        name="username"
                        size="lg"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        v-model="password"
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="lg"
                      />
                    </div>

                    <!-- Thêm radio button để chọn kiểu đăng nhập -->
                    <div class="mb-3">
                      <label><input type="radio" v-model="loginType" value="admin" /> Admin</label>
                      <label class="ms-3"><input type="radio" v-model="loginType" value="seller" /> Seller</label>
                    </div>

                    <argon-switch id="rememberMe" name="remember-me">Remember me</argon-switch>
                    <div v-if="loginError" class="text-danger">{{ loginError }}</div>  
                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                        type="submit"
                      >Sign in</argon-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg&quot;);
                  background-size: cover;
                "
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4 class="mt-5 text-white font-weight-bolder position-relative">
                  "Attention is the new currency"
                </h4>
                <p class="text-white position-relative">
                  The more effortless the writing looks, the more effort the
                  writer actually put into the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
