<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Users Table (Total: {{ users.length }})</h6>
      <!-- Search Input -->
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Search by User ID, Username, First Name, etc."
        class="form-control mt-3"
      />
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div v-if="isLoading" class="text-center my-4">
        <p>Loading...</p>
      </div>
      <div v-else class="table-responsive p-0">
        <table class="table align-items-center ">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">User ID</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Username</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">First Name</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Level User</th>
               <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Balance Ton</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Balance Tonone</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Created At</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in filteredUsers" :key="user.id">
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ user.id }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ user.username }}</p>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ user.first_name }}</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ user.levelUser }}</span>
              </td>
                  <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ user.balance_ton?.toFixed(2) }}</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ user.balance_token?.toFixed(3) }}</span>
              </td>
              <td class="align-middle text-center">
                <span :class="{'badge badge-sm bg-gradient-success': user.status, 'badge badge-sm bg-gradient-secondary': !user.status}">
                  {{ user.status ? 'Active' : 'Inactive' }}
                </span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ new Date(user.createdAt).toLocaleDateString() }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Pagination Controls -->
    <div class="pagination mt-3 d-flex justify-content-center align-items-center">
  <button 
    :disabled="page === 1" 
    @click="page--" 
    class="px-3 py-1 bg-gray-300 rounded disabled:opacity-50 me-2"
  >
    Previous
  </button>
  <span class="mx-3">Page {{ page }} of {{ totalPages }}</span>
  <button 
    :disabled="page === totalPages" 
    @click="page++" 
    class="px-3 py-1 bg-gray-300 rounded disabled:opacity-50 ms-2"
  >
    Next
  </button>
</div>

    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import axiosInstance from '@/utils/axios';

const users = ref([]);
const isLoading = ref(false);
const page = ref(1);   
const pageSize = ref(10);  
const totalPages = ref(0);
const searchQuery = ref('');   

const fetchUsers = async () => {
  isLoading.value = true;
  try {
    const { data } = await axiosInstance.get('/api/pagination/user', {
      params: { page: page.value, pageSize: pageSize.value }
    });
    if (data.success && Array.isArray(data.data)) {
      users.value = data.data; 
      totalPages.value = Math.ceil(data.pagination.totalItems / pageSize.value);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    isLoading.value = false;
  }
};

const filteredUsers = computed(() => {
  if (!searchQuery.value) {
    return users.value;
  }
  return users.value.filter(user =>
    user.username?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
    user.first_name?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
    user.id?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
    user.levelUser?.toString().includes(searchQuery.value) ||
    user.balance_token?.toString().includes(searchQuery.value) ||
    user.status?.toString().toLowerCase().includes(searchQuery.value.toLowerCase()) ||
    new Date(user.createdAt).toLocaleDateString().includes(searchQuery.value)
  );
});

watch(page, fetchUsers);

onMounted(fetchUsers);
</script>

<style scoped>
.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;

}
</style>
