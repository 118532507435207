import { createStore } from "vuex";
import axios from 'axios';
const initializeAxios = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

const storedUser = JSON.parse(localStorage.getItem('auth'));
const initialToken = storedUser ? storedUser.accessToken : null;
const LOGIN_URL_ADMIN = "/api/login/admin";
const LOGIN_URL_SELLER = "/api/login/seller";


initializeAxios(initialToken);
export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: false,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    user: storedUser || null,
    token: initialToken || null,
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    sidebarMinimize(state) {
      let sidenav_show = document.querySelector("#app");
      if (state.isPinned) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        state.isPinned = true;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      state.isNavFixed = !state.isNavFixed;
    },
    setUser(state, user) {
      state.user = user;
      state.token = user ? user.accessToken : null;  
      initializeAxios(state.token);  
    },
    setToken(state, token) {
      state.token = token;
      initializeAxios(token);  
    },
    logout(state) {
      state.user = null;
      state.token = null;
      localStorage.removeItem('auth');

      initializeAxios(null);  
    }
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
async login({ commit }, { username, password, loginType }) {
  try {

    const loginUrl = loginType === 'admin' ? LOGIN_URL_ADMIN : LOGIN_URL_SELLER;

    const { data } = await axios.post(loginUrl, { username, password });

    commit('setUser', data.user);
    commit('setToken', data.user.accessToken);
    localStorage.setItem('auth', JSON.stringify(data.user)); 


  } catch (error) {
    console.error('Đăng nhập thất bại:', error);
    throw error;
  }
},
    logout({ commit }) {
      commit('logout');
    }
  },
  getters: {
    isAuthenticated: state => !!state.user,
    user: state => state.user,
  }
});
