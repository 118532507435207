<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Card Configs Table</h6>
      <button @click="openModal('add')" class="btn btn-primary mb-3">Add New Config</button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">STT</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Coin ID</th>

              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Number</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(card, index) in cards" :key="card._id">
              <td>{{ index + 1 }}</td>
              <td>{{ card.id_card?._id || 'Unknown' }}</td>
      
              <td>{{ formatNumber(card.number) }}</td>
              <td>
                <button @click="openModal('edit', card)" class="btn btn-warning me-2">Edit</button>

                <button @click="deleteCard(card._id)" class=" btn btn-danger">Delete</button>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal for Adding or Editing a Card -->
    <div v-if="showModal" class="modal show" tabindex="-1" role="dialog" style="display: block; background-color: rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isEditing ? 'Edit Config' : 'Add New Config' }}</h5>
            <button type="button" class="close" @click="closeModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
        <form @submit.prevent="handleSave">
  <div class="form-group">
    <label>Card ID</label>
    <input v-model="formData.id_card" type="text" class="form-control" required />
  </div>
  <div class="form-group">
    <label>Number</label>
    <input v-model="formData.number" type="number" class="form-control" required />
  </div>
  <button type="submit" class="btn btn-primary">{{ isEditing ? 'Update' : 'Create' }}</button>
</form>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axiosInstance from '@/utils/axios';

// State variables for cards data, modal visibility, etc.
const cards = ref([]);
const showModal = ref(false);
const isEditing = ref(false);
const currentCardId = ref(null);
const coins = ref([]);



// Form data used for both Add and Edit operations
const formData = ref({
  id_card: '',  
  number: 0,  
});

// Format numbers for displaying
const formatNumber = (value) => {
  return value !== undefined && value !== null ? value : 0;
};

// Fetch all card data from API
const fetchCards = async () => {
  try {
    
    const response = await axiosInstance.get('/api/all/card/buy');
    cards.value = response.data.data;  
    console.log(response,"ádasd")
      const responsecoin = await axiosInstance.get('/api/all/card');
    coins.value = responsecoin.data.data;
    console.log(responsecoin)
   } catch (error) {
    console.error('Error fetching card configs:', error);
  }
};

// Open modal for adding or editing a card configuration
const openModal = (mode, card = null) => {
  showModal.value = true;
  if (mode === 'edit' && card) {
    isEditing.value = true;
    currentCardId.value = card._id;
    formData.value = {
      id_coin:  '',  
   
      number: card.number || 0,  
    };
  } else {
    isEditing.value = false;
    resetForm();
  }
};

// Close modal and reset the form data
const closeModal = () => {
  showModal.value = false;
  resetForm();
};

const handleSave = async () => {
  try {
    const cardData = {
      id_card: formData.value.id_card,   
      number: formData.value.number,  
    };

    if (isEditing.value) {
 
      await axiosInstance.put(`/api/update/card/buy/${currentCardId.value}`, cardData);
    } else {
    
      await axiosInstance.post('/api/save/card/buy', cardData);
    }

    fetchCards();  
    closeModal();  
  } catch (error) {
    console.error('Error saving card config:', error.response ? error.response.data : error.message);
  }
};
 
const deleteCard = async (cardId) => {
  try {
    await axiosInstance.delete(`/api/delete/card/buy/${cardId}`);
    fetchCards();   
  } catch (error) {
    console.error('Error deleting card config:', error);
  }
};



// Reset the form to its initial state
const resetForm = () => {
  formData.value = {
    id_coin: '',
    quantity: 0,
    numberWins: 0,
    remaining: 0,
    number: 0,  // Reset the number field
  };
  currentCardId.value = null;
  isEditing.value = false;
};

// Fetch card data on component mount
onMounted(fetchCards);
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal .modal-dialog {
  margin: 10% auto;
}
</style>
