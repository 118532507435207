<template>
  <div class="p-3">
    <button @click="handleAdd" class=" btn btn-primary" style="margin-bottom: 16px;">
      Add Seller
    </button>
    <a-table
      :columns="columns"
      :dataSource="sellers"
      rowKey="_id"
      v-if="!isLoading && !error"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'actions'">
          <button
            @click="handleEdit(record)"
            class="ant-btn ant-btn-link btn "
            style="margin-right: 8px;"
          >
            Edit
          </button>
          <!-- <button
            @click="handleDelete(record._id)"
            class="ant-btn ant-btn-link btn btn-danger"
          >
            Delete
          </button> -->
        </template>
      </template>
    </a-table>
    <a-spin v-if="isLoading" size="large" class="flex w-full justify-center h-1/2 top-1/2"></a-spin>
    <a-alert v-if="error" type="error" show-icon :message="'Error'" :description="error" />

    <a-modal
      :title="isEdit ? 'Edit Seller' : 'Add Seller'"
      v-model:visible="isModalVisible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form :model="form" layout="vertical">
        <a-form-item
          label="Username"
          :rules="[{ required: true, message: 'Please enter the username' }]"
        >
          <a-input v-model:value="form.username" />
        </a-form-item>
        <a-form-item
          v-if="!isEdit"
          label="Password"
          :rules="[{ required: true, message: 'Please enter the password' }]"
        >
          <a-input-password v-model:value="form.password" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { message } from 'ant-design-vue';
import axiosInstance from '@/utils/axios';

// Reactive state
const isModalVisible = ref(false);
const isEdit = ref(false);
const currentSeller = ref(null);
const form = reactive({
  username: '',
  password: '',
});
const sellers = ref([]);
const isLoading = ref(false);
const error = ref(null);

// Fetch sellers on component mount
const fetchSellers = async () => {
  isLoading.value = true;
  error.value = null;
  try {
    const response = await axiosInstance.get('/api/all/seller');
    sellers.value = response.data.data;
  } catch (err) {
    error.value = err.message || 'Failed to fetch sellers';
  } finally {
    isLoading.value = false;
  }
};

onMounted(fetchSellers);

// Add seller
const addSeller = async () => {
  try {
    await axiosInstance.post('/api/create/seller', form);
    message.success('Seller added successfully');
    fetchSellers(); // Refresh list
  } catch (err) {
    message.error(`Error adding seller: ${err.message}`);
  }
};

// Update seller
const updateSeller = async () => {
  try {
    await axiosInstance.put(`/api/update/seller/${currentSeller.value._id}`, form);
    message.success('Seller updated successfully');
    fetchSellers(); // Refresh list
  } catch (err) {
    message.error(`Error updating seller: ${err.message}`);
  }
};

// Delete seller
// const deleteSeller = async (_id) => {
//   try {
//     await axiosInstance.delete(`/api/delete/seller/${_id}`);
//     message.success('Seller deleted successfully');
//     fetchSellers(); // Refresh list
//   } catch (err) {
//     message.error(`Error deleting seller: ${err.message}`);
//   }
// };

// Handlers
const handleAdd = () => {
  isEdit.value = false;
  currentSeller.value = null;
  form.username = '';
  form.password = '';
  isModalVisible.value = true;
};

const handleEdit = (record) => {
  isEdit.value = true;
  currentSeller.value = record;
  form.username = record.username;
  isModalVisible.value = true;
};

// const handleDelete = (_id) => {
//   deleteSeller(_id);
// };

const handleOk = async () => {
  try {
    if (isEdit.value && currentSeller.value) {
      await updateSeller();
    } else {
      await addSeller();
    }
    isModalVisible.value = false;
  } catch (err) {
    console.error('Validation failed:', err);
  }
};

const handleCancel = () => {
  isModalVisible.value = false;
};

// Table columns
const columns = [
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Actions',
    key: 'actions',
  },
];
</script>

<style scoped>
/* Add your styles here */
</style>
