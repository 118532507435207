<!-- <script setup>
import { ref, onMounted, watch, computed } from 'vue';
import axiosInstance from '@/utils/axios';

const allUsers = ref([]);  
const users = ref([]);  
const page = ref(1);   
const pageSize = ref(10);  
const totalPages = ref(0);  
const searchQuery = ref('');  

const fetchUsers = async () => {
  try {
    const { data } = await axiosInstance.get('/api/pagination/order');

    if (data && Array.isArray(data.data)) {
      allUsers.value = data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else {
      allUsers.value = [];
    }

    totalPages.value = Math.ceil(filteredUsers.value.length / pageSize.value);
    updatePage();
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

 
const filteredUsers = computed(() => {
  if (!searchQuery.value) {
    return allUsers.value;
  }
  return allUsers.value.filter(user =>
    user.userId?.username?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
    user.userId?.id?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
    user.transactionId?.hash?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
    user.transactionId?.from?.toLowerCase().includes(searchQuery.value.toLowerCase())   
  );
});

const updatePage = () => {
  const start = (page.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  users.value = filteredUsers.value.slice(start, end);
};

const prevPage = () => {
  if (page.value > 1) {
    page.value--;
    updatePage();
  }
};

const nextPage = () => {
  if (page.value < totalPages.value) {
    page.value++;
    updatePage();
  }
};

onMounted(fetchUsers);

watch([filteredUsers, page, pageSize], () => {
  totalPages.value = Math.ceil(filteredUsers.value.length / pageSize.value);
  updatePage();
});
</script> -->
<script setup>
import { ref, onMounted, watch } from 'vue';
import axiosInstance from '@/utils/axios';

const users = ref([]);  
const page = ref(1);   
const pageSize = ref(10);  
const totalPages = ref(0);  
const totalItems = ref(0);  
const searchQuery = ref('');  

const fetchUsers = async () => {
  try {
    const response = await axiosInstance.get('/api/pagination/order', {
      params: {
        page: page.value,
        limit: pageSize.value,
        search: searchQuery.value // If you need to handle search server-side
      }

    });
console.log(response,"order")
    const data = response.data;
    if (data.success) {
      users.value = data.data;
      totalItems.value = data.pagination.totalItems;
      totalPages.value = data.pagination.totalPages;
    } else {
      console.error('Error fetching data:', data.message);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const prevPage = () => {
  if (page.value > 1) {
    page.value--;
    fetchUsers();
  }
};

const nextPage = () => {
  if (page.value < totalPages.value) {
    page.value++;
    fetchUsers();
  }
};

onMounted(fetchUsers);

watch([pageSize, searchQuery], () => {
  fetchUsers();
});
</script>

<!-- <template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Order Table</h6>
       
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Search by User ID, Username, Transaction Hash, or From field"
        class="form-control mt-3"
      />
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center text-center mb-0">
          <thead>
            <tr>
              <th>User ID</th>
              <th>Username</th>
              <th>First Name</th>
              <th>Level User</th>
              <th>Transaction</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user._id">
              <td>{{ user.userId?.id }}</td>
              <td>{{ user.userId?.username }}</td>
              <td>{{ user.userId?.first_name }}</td>
              <td>{{ user.userId?.levelUser }}</td>
              <td>
                From: {{ user.transactionId?.from }}<br>
                To: {{ user.transactionId?.to }}<br>
                Comment: {{ user.transactionId?.hash }}<br>
                CreatedAt: {{ user.transactionId?.createdAt }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination mt-3 flex justify-content-center">
        <button @click="prevPage" :disabled="page === 1" class="btn btn-sm btn-primary">Previous</button>
        <span class="mx-2">{{ page }} / {{ totalPages }}</span>
        <button @click="nextPage" :disabled="page >= totalPages" class="btn btn-sm btn-primary">Next</button>
      </div>
    </div>
  </div>
</template> -->
<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Order Table</h6>
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Search by User ID, Username, Transaction Hash, or From field"
        class="form-control mt-3"
      />
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center text-center mb-0">
          <thead>
            <tr>
              <th>User ID</th>
              <th>Username</th>
              <th>First Name</th>
              <th>Level User</th>
              <th>Transaction</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user._id">
              <td>{{ user.userId?.id }}</td>
              <td>{{ user.userId?.username }}</td>
              <td>{{ user.userId?.first_name }}</td>
              <td>{{ user.userId?.levelUser }}</td>
              <td>
                From: {{ user.transactionId?.from }}<br>
                To: {{ user.transactionId?.to }}<br>
                CreatedAt: {{ user.transactionId?.createdAt }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination mt-3 flex justify-content-center">
        <button @click="prevPage" :disabled="page === 1" class="btn btn-sm btn-primary">Previous</button>
        <span class="mx-2">{{ page }} / {{ totalPages }}</span>
        <button @click="nextPage" :disabled="page >= totalPages" class="btn btn-sm btn-primary">Next</button>
      </div>
    </div>
  </div>
</template>
